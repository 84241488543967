<template>
    <div class="container mt-5">


        <h2>
            <i class="bi bi-person-lines-fill"></i>
            Liste des utilisateurs
        </h2>

        <div class="my-3">

          <button
              type="button"
              tag="button"
              class="btn btn-primary btn-sm mx-3"
              data-bs-toggle="modal"
              @click.prevent="createUser()"
              data-bs-target="#newUserModal"
          >
              <i class="bi bi-pencil-square"></i>
              New user
        </button>

        <button
            type="button"
            class="btn btn-secondary btn-sm mx-3"
            data-bs-toggle="modal"
            @click.prevent="showModal()"
            data-bs-target="#newUserModal_2"
        >
            <i class="bi bi-pencil-square"></i>
            Show Modal 2
      </button>

        </div>

        <div class="my-3">
            <div class="form-check form-check-inline">
                <label class="form-check-label" for="filters">Filters on :</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(0)">
                <label class="form-check-label" for="news">News</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(1)">
                <label class="form-check-label" for="users">Users</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(2)">
                <label class="form-check-label" for="masters">Masters</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(3)">
                <label class="form-check-label" for="admins">Admins</label>
            </div>
        </div>

        <div
            v-show="state.form.status !== 'success'"
            class="spinner"
        >
            Loading users...
        </div>

        <div
            v-if="state.form.status === 'success'"
        >

            <div class="table-responsive">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">name</th>
                            <th scope="col">email</th>
                            <th scope="col">role</th>
                            <th scope="col">created at</th>
                            <th scope="col">updated at</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Validate</th>
                            <th sModalcope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="user in selectedUsers"
                            v-bind:key="user"
                        >
                            <td>{{ user.id }}</td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.role }}</td>
                            <td>{{ user.created_at }}</td>
                            <td>{{ user.updated_at }}</td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#editUserModal"
                                    @click.prevent="editResource(user)"
                                >
                                    <i class="bi bi-pencil-fill"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    v-if="user.role == 0"
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="validateResource(user.id)"
                                >
                                    <i class="bi bi-check-square"></i>
                                </a>
                            </td>
                            <td>

                                <a
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="deleteResource(user.id)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <MyModal
            v-show="state.modal.isModalVisible"
            @close="closeModal"
            v-bind:idProps="'newUserModal'"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                Create new user
            </template>

            <template v-slot:body>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="nameInput"
                        placeholder="name"
                        v-model="state.form.body.name"
                    >
                    <label for="nameInput">Username</label>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="email"
                        class="form-control rounded-4"
                        id="emailInput"
                        placeholder="name@example.com"
                        v-model="state.form.body.email"
                    >
                    <label for="emailInput">Email address</label>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="password"
                        class="form-control rounded-4"
                        id="passwordInput"
                        placeholder="Password"
                        v-model="state.form.body.password"
                    >
                    <label for="passwordInput">Password</label>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="roleInput"
                        v-model="state.form.body.role"
                    >
                        <option value="">Select role...</option>
                        <option v-for="option in state.form.options" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>

                    <label for="roleInput">Role</label>
                </div>



                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                    data-bs-dismiss="modal"
                >Save</button>
            <!--
                </form>
            -->
            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal>


        <MyModal
            v-show="state.modal.isModalVisible"
            @close="closeModal"
            v-bind:idProps="'editUserModal'"
        >
            <template v-slot:header>
                <h2 class="fw-bold mb-0">Edit user</h2>
            </template>

            <template v-slot:body>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="nameInput"
                        placeholder="name"
                        v-model="state.form.body.name"
                    >
                    <label for="nameInput">Username</label>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="email"
                        class="form-control rounded-4"
                        id="emailInput"
                        placeholder="name@example.com"
                        v-model="state.form.body.email"
                    >
                    <label for="emailInput">Email address</label>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="password"
                        class="form-control rounded-4"
                        id="passwordInput"
                        placeholder="Password"
                        v-model="state.form.body.password"
                    >
                    <label for="passwordInput">Password</label>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="roleInput"
                        v-model="state.form.body.role"
                    >
                        <option value="">Select role...</option>
                        <option v-for="option in state.form.options" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>

                    <label for="roleInput">Role</label>
                </div>



                <button
                    @click="updateResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                    data-bs-dismiss="modal"
                >Update</button>
            <!--
                </form>
            -->
            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal>



        <MyModal2
            v-show="state.modal.isModalVisible"
            @close="closeModal"
            v-bind:idProps="'newUserModal_2'"
            v-bind:resource="state.user"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                Create new user
            </template>

            <template v-slot:body>

                <button
                    @click="testFunction()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                    data-bs-dismiss="modal"
                >Sign up</button>

                <small class="text-muted">By clicking Sign up, you agree to the terms of use.</small>

            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal2>


    </div>
</template>

<script>
import { reactive, computed } from 'vue'
import { onMounted } from 'vue'
import axios       from 'axios'

import MyModal from '@/components/MyModal.vue'
import MyModal2 from '@/components/MyModal_2.vue'

// import composables files
import useObjectsUtils from '@/composables/useObjectsUtils.js'
//import useUsersComp from '@/composables/useUsersComp.js'

export default {

    name: 'users',

    components : {
        MyModal,
        MyModal2,
    },


    setup() {
        const objUtils = useObjectsUtils()
        //const useUsers = useUsersComp()

        const state = reactive({

            // -----------------------------------------
            // user structure for Modal 2
            user : {
                name: {
                    type: 'text',
                    label: 'User name',
                    placeholder: 'username',
                },
                email: {
                    type: 'email',
                    label: 'Email address',
                    placeholder: 'name@example.com',
                },
            },
            // -----------------------------------------


            form: {
                body: {
                    name: '',
                    email: '',
                    password: '',
                    role: 0,
                },
                options: [
                    { text: '0: New', value: 0 },
                    { text: '1: User', value: 1 },
                    { text: '2: Master', value: 2 },
                    { text: '3: Admin', value: 3 },
                ],

                status: null,
                data: null
            },

            checkedUsers: [0,1],
            modal: {
                isModalVisible: false,
            }
        });

        onMounted(() => {
            getUsers()
        });

        function showModal() {
            state.modal.isModalVisible = true
        }

        function closeModal() {
            state.modal.isModalVisible = false
        }

        function testFunction() {
            closeModal()
        }

        function createUser() {
            state.form.body.name = null
            state.form.body.email = ''
            state.form.body.password = ''
            state.form.body.role = 0
        }

        function saveResource() {
            axios({
                method: 'post',
                url: 'users/',
                data: state.form.body
            })
            .then((res) => {
                state.form.data.push(res.data.resource)

                closeModal()
            })
        }

        function editResource(resource) {
            state.form.body = objUtils.cloneObject(resource)
        }

        function updateResource() {

            let id = state.form.body.id

            axios({
                method: 'put',
                url: 'users/' + id,
                data: state.form.body
            })
            .then((res) => {

                var index = state.form.data.findIndex(x => x.id === res.data.resource.id)

                // if resource not existing in array
                if (index < 0) {
                    // add resource to array
                    state.form.data.push(res.resource)
                } else {
                    // update resource in array
                    state.form.data.splice(index, 1, res.data.resource);
                    //Vue.set(state.form.data, index, res.resource)
                }

                closeModal()
            })
        }

        function validateResource(id) {

            axios({
                method: 'put',
                url: 'users/' + id + '/validate',
                data: id
            })
            .then((res) => {

                var index = state.form.data.findIndex(x => x.id === res.data.resource.id)

                // if resource not existing
                if (index < 0) {
                    // add resource to array
                    state.form.data.push(res.data.resource)
                } else {
                    // update resource in array
                    state.form.data.splice(index, 1, res.data.resource);
                }

            })
        }

        function deleteResource(id) {
            axios({
                method: 'delete',
                url: 'users/' + id,
                data: id
            })
            .then(() => {
                var index = state.form.data.findIndex(x => x.id === id);
                state.form.data.splice(index, 1)
            })
        }

        function getUsers() {

            state.form.status = 'loading';

            axios({
                method: 'get',
                url: 'users'
            })
            .then((res) => {
                state.form.data = res.data.data;

                state.form.status = 'success';
            });
        }

        const selectedUsers = computed(() => {
            let length = state.checkedUsers.length;
            var selectedUsersToShow = [];

            for (var i = 0; i < length; i++) {
                selectedUsersToShow[i] = state.checkedUsers[i];
            }

            return state.form.data.filter(function(usr) {
                return selectedUsersToShow.includes(usr.role);
            })
        })

        return {
            state,
            showModal,
            closeModal,
            selectedUsers,
            //impersonateComp,
            //impersonateDefault,
            createUser,
            saveResource,
            editResource,
            updateResource,
            deleteResource,
            validateResource,
            testFunction
        }
    }
}
</script>

<style>
    @import '../../assets/css/logo.css';
</style>
