<template>


    <div class="modal fade" id="ExampleModal" v-bind="{ id: idProps }" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">

            <div class="modal-content rounded-5 shadow">
                <div class="modal-header p-5 pb-4 border-bottom-0">
                    <!-- <h5 class="modal-title">Modal title</h5> -->

                    <h3 class="fw-bold mb-0">
                        <slot name="header">
                            Default title
                        </slot>
                    </h3>

                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        @click="$emit('close')"
                    ></button>
                </div>

                <div class="modal-body p-5 pt-0">

                    <div
                        class="form-floating mb-3"
                        v-for="( attributes, index ) in resource"
                        v-bind:key="index"
                    >
                        <input
                            :type="attributes.type"
                            class="form-control rounded-4"
                            :id="'floatingInput_' + index"
                            :placeholder="'data_'  + attributes.placeholder"
                        >
                        <label :for="'floatingInput_' + index">{{ attributes.label }}</label>
                    </div>

                    <slot name="body">
                        This is the default body!
                    </slot>


                </div>


                <div class="modal-footer flex-nowrap p-0">
                    <slot name="footer"></slot>
                </div>

            </div>
        </div>
    </div>


</template>

<script>
import { onMounted } from 'vue';

export default {

    name: 'Modal2',

    emits: ['close'],

    props: {
        idProps: {
            type: String,
            required: true
        },
        resource: {
            type: Object,
            required: true
        }
    },

    setup() {


        onMounted(() => {
            //console.log('hello from onMounted')
        })

        return {

        }

    }

}
</script>
